import React from 'react';
import styled from '@emotion/styled';
import { colors, widths, respondFrom, breakpoints, css } from '@styles';
import * as utils from '@utils';

import quote from '@assets/svg/article-quote.svg';

const ArticleQuoteWrapper = styled.div`
  background-color: ${colors.grayNurse};
  margin: 20px 0;

  ${respondFrom(
    breakpoints.md,
    css`
      margin: 40px 0;
    `
  )}
`;

const ArticleQuoteInner = styled.div`
  position: relative;
  max-width: ${widths.articleMax}px;
  margin: auto;
  padding: 65px 20px 13px 30px;

  ${respondFrom(
    breakpoints.lg,
    css`
      padding: 40px 0;
    `
  )}

  img {
    position: absolute;
    top: 30px;
    left: 26px;

    ${respondFrom(
      breakpoints.lg,
      css`
        left: -60px;
      `
    )}
  }

  h4 {
    font-weight: 300;
    font-style: italic;
    color: ${colors.text.article};
  }
`;

export const ArticleSectionQuote = ({ data }) => (
  <>
    {data && data.content && data.content !== '' && (
      <ArticleQuoteWrapper>
        <ArticleQuoteInner>
          <img src={quote} alt="" />
          <h4>{utils.SafeHtml(data.content)}</h4>
        </ArticleQuoteInner>
      </ArticleQuoteWrapper>
    )}
  </>
);
