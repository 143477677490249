import React from 'react';
import styled from '@emotion/styled';
import dateFormat from 'dateformat';
import { colors, dimensions, widths, respondFrom, breakpoints, css } from '@styles';
import { Blog, NewsSettings } from '@content/types/blog';
import { Link } from '@utils';
import ButtonLink from '@components/common/ButtonLink';
import * as utils from '@utils';

import clockIcon from '@assets/svg/feature-incl-icon-2.svg';

const HeaderWrapper = styled.div`
  max-width: ${widths.articleMax}px;
  margin: auto;
  padding: 20px 0;

  ${respondFrom(
    breakpoints.xl,
    css`
      padding: 40px 0;
    `
  )}

  h1 {
    font-size: 40px;
    margin: 10px 0 15px;
  }
`;

const HeaderCategory = styled(Link)`
  font-size: ${dimensions.fontSize.small}px;
  font-weight: 300;
  text-transform: uppercase;
  color: ${colors.text.article};
  margin-right: 10px;

  &:hover {
    text-decoration: none;
  }
`;

const HeaderTag = styled(Link)`
  font-size: ${dimensions.fontSize.small}px;
  font-weight: 300;
  color: ${colors.green.medium};
  margin-right: 10px;
`;

const HeaderDateTime = styled.div`
  span {
    margin-right: 5px;
    color: ${colors.text.article};
    font-size: ${dimensions.fontSize.small}px;
    font-weight: 300;
  }

  img {
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
    margin-right: 5px;
  }
`;

const HeaderReturnLink = styled.div`
  border-bottom: 1px solid ${colors.gray};
  margin-bottom: 20px;

  ${respondFrom(
    breakpoints.xl,
    css`
      position: absolute;
      top: 28px;
      left: 32px;
      margin: 0;
      border: none;
    `
  )}
`;

interface ArticleHeaderProps {
  article: Blog | null;
  settings: NewsSettings;
  langcode: string;
  urls: Array<string>;
}

export const ArticleHeader = (props: ArticleHeaderProps) => {
  const { article } = props;

  return (
    <HeaderWrapper>
      <HeaderReturnLink>
        <ButtonLink
          to={utils.langLink(props.langcode, props.urls[15])}
          color="text-light"
          icon="arrow-left"
          size="small"
          className="to-left"
        >
          <span>{props.settings.text5}</span>
        </ButtonLink>
      </HeaderReturnLink>
      <div>
        {article &&
          article.categories.map((cat, index) => (
            <HeaderCategory
              key={index}
              to={utils.langLink(
                props.langcode,
                `${props.urls[15]}/${encodeURI(utils.escapeDiacritics(cat).toLocaleLowerCase())}`
              )}
            >
              {cat}
            </HeaderCategory>
          ))}
        {article &&
          article.tags.map((tag, index) => (
            <HeaderTag
              key={index}
              to={utils.langLink(
                props.langcode,
                `${props.urls[15]}/${encodeURI(utils.escapeDiacritics(tag).toLocaleLowerCase())}`
              )}
            >
              {tag}
            </HeaderTag>
          ))}
      </div>
      <h1>{article && article.title}</h1>
      <HeaderDateTime>
        <span>{dateFormat(article && article.date, 'dd.mm.yyyy')}</span>
        <span>-</span>
        <span>
          <img src={clockIcon} alt="" />
          {article && article.timeToRead} min.
        </span>
      </HeaderDateTime>
    </HeaderWrapper>
  );
};
