import React from 'react';
import styled from '@emotion/styled';
import { colors, widths, respondFrom, breakpoints, css } from '@styles';
import ButtonLink from '@components/common/ButtonLink';
import { BlogSections } from '@content/types/blog';
import * as utils from '@utils';

const ButtonTextWrapper = styled.div`
  background-color: ${colors.grayNurse};
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: ${colors.grayNurse};
  }

  &::before {
    left: -50vw;
    width: 50vw;
  }

  &::after {
    right: -50vw;
    width: 50vw;
  }
`;

const ButtonTextInner = styled.div`
  max-width: ${widths.articleMax}px;
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 45px 0;

  ${respondFrom(
    breakpoints.md,
    css`
      flex-flow: row;

      .content-wrapper {
        margin-right: 20px;
      }
    `
  )}

  p {
    margin: 0;
    flex: 1;
    line-height: 1.5;
  }

  a {
    margin-right: auto;
    margin-top: 25px;

    ${respondFrom(
      breakpoints.md,
      css`
        margin: 0;
      `
    )}
  }
`;

interface ArticleSectionButtonTextProps {
  data: BlogSections;
  langcode: string;
  urls: Array<string>;
}

export const ArticleSectionButtonText = ({
  data,
  langcode,
  urls,
}: ArticleSectionButtonTextProps) => (
  <ButtonTextWrapper>
    <ButtonTextInner>
      <div className="content-wrapper">{utils.SafeHtml(data.content)}</div>
      {data.buttonHref && data.buttonHref !== '' && (
        <ButtonLink
          to={utils.langLink(langcode, data.buttonHref)}
          icon="arrow-right"
          extend
          target="_self"
        >
          {data.buttonLabel}
        </ButtonLink>
      )}
    </ButtonTextInner>
  </ButtonTextWrapper>
);
