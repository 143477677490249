import React, { Component } from 'react';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css } from '@styles';
import { Blog, NewsSettings } from '@content/types/blog';
import { BlogArticleItem } from '@components/blog/BlogArticleItem';

const RelatedArticlesWrapper = styled.div`
  margin-top: 45px;

  ${respondFrom(
    breakpoints.md,
    css`
      margin-top: 65px;
    `
  )}

  h2 {
    text-align: center;
    margin: 45px 0;
  }
`;

const RelatedArticlesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: column;

  ${respondFrom(
    breakpoints.md,
    css`
      flex-flow: row;
      justify-content: space-evenly;
    `
  )}
`;

interface ArticleRelatedArticlesProps {
  allArticles: Blog[];
  settings: NewsSettings;
  langcode: string;
  urls: Array<string>;
}

export class ArticleRelatedArticles extends Component<ArticleRelatedArticlesProps> {
  state = {
    relatedArticles: [],
  };

  componentDidMount() {
    const relatedArticles: Array<Blog> = [];

    this.props.allArticles.map(
      (article, index) => article.related && index < 3 && relatedArticles.push(article)
    );

    this.setState({ relatedArticles });
  }

  render() {
    return (
      <>
        {this.state.relatedArticles.length > 0 && (
          <RelatedArticlesWrapper>
            <h2>{this.props.settings.text7}</h2>
            <RelatedArticlesList>
              {this.props.allArticles.map((article, index) => (
                <BlogArticleItem
                  key={index}
                  article={article}
                  noIntroduction={true}
                  row={3}
                  settings={this.props.settings}
                  langcode={this.props.langcode}
                  urls={this.props.urls}
                />
              ))}
            </RelatedArticlesList>
          </RelatedArticlesWrapper>
        )}
      </>
    );
  }
}
