import React, { useState } from 'react';
import styled from '@emotion/styled';
import Image, { GatsbyImageProps } from 'gatsby-image';
import ReactPlayer from 'react-player';
import PlayIcon from '@assets/images/play-button.png';

const VideoWrapper = styled.div`
  position: relative;
  z-index: 99;
  width: 100%;
  height: 100%;
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  cursor: pointer;
  padding-bottom: 56.25%;
  height: 0;
`;

const PlayButton = styled.div`
  background-image: url(${PlayIcon});
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: absolute;
  height: 100px;
  width: 100px;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  z-index: 99;
`;

const VideoThumbnail = styled(Image)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: auto !important;
  margin: auto;
`;

const PlayerContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
`;

const Player = styled(ReactPlayer)`
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

interface VideoPlayerProps {
  image: GatsbyImageProps | null;
  imageAlt: string | undefined;
  videoUrl: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = props => {
  const [play, setPlay] = useState<boolean>(false);

  const handleThumbnailClick = () => {
    setPlay(true);
  };

  const handleOnEnded = () => {
    setPlay(false);
  };

  return (
    <VideoWrapper>
      <ThumbnailWrapper onClick={handleThumbnailClick} hidden={play}>
        <PlayButton />
        {props.image && (
          <VideoThumbnail
            imgStyle={{ objectFit: 'contain', width: '100%' }}
            style={{ position: 'absolute' }}
            {...props.image}
            alt={props.imageAlt}
          />
        )}
      </ThumbnailWrapper>
      <PlayerContainer hidden={!play}>
        <Player
          controls={true}
          playing={play}
          onEnded={handleOnEnded}
          url={props.videoUrl + '?rel=0'}
          width="100%"
        />
      </PlayerContainer>
    </VideoWrapper>
  );
};

export default VideoPlayer;
