/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Page from '@components/layout/Page';
import Container from '@components/layout/Container';
import { ArticleGrid } from '@components/article/ArticleGrid';
import { NewsDataProps } from '@graphql/queries/NewsQuery';
import { PageContext } from '@content/types/pageContext';
import MetaPage from '@components/common/MetaPage';
import { LocationDependent } from '@content/types/general';

export interface NewsProps extends NewsDataProps, LocationDependent {
  pageContext: PageContext;
  urls: Array<string>;
}

export default (props: NewsProps) => {
  const article =
    props.allNews[props.pageContext.langcode].find(
      article => article.slug === props.pageContext.slug
    ) || null;

  return (
    <Page>
      {article && <MetaPage title={article.seo.title} description={article.seo.description} image={article.seo.image} />}
      <Container>
        <ArticleGrid
          article={article}
          href={props.location.href}
          allArticles={props.allNews[props.pageContext.langcode]}
          settings={props.newsSettings[props.pageContext.langcode]}
          langcode={props.pageContext.langcode}
          urls={props.urls}
        />
      </Container>
    </Page>
  );
};
