import React from 'react';
import styled from '@emotion/styled';
import { widths, respondFrom, breakpoints, css } from '@styles';
import * as utils from '@utils';
import VideoPlayer from '@components/common/VideoPlayer';

const ArticleSectionVideoWrapper = styled.div`
  max-width: ${widths.articleMax}px;
  margin: 20px auto;

  ${respondFrom(
    breakpoints.md,
    css`
      flex-flow: row;
      margin: 40px auto;
    `
  )}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 45px 0 17px;
    font-weight: 100;

    p,
    span {
      font-weight: 100;
    }
  }

  p {
    margin: 17px 0;
    line-height: 1.5;
  }
`;

export const ArticleSectionVideo = ({ data }) => {
  return (
    <ArticleSectionVideoWrapper>
      <h2>{data.title}</h2>
      {utils.SafeHtml(data.content)}
      <VideoPlayer
        image={data.image.childImageSharp}
        imageAlt={data.imageAlt}
        videoUrl={data.video}
      />
    </ArticleSectionVideoWrapper>
  );
};
