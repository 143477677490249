import React from 'react';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css } from '@styles';
import { Blog } from '@content/types/blog';
import Image from 'gatsby-image';

const ArticleImageWrapper = styled.div`
  position: relative;
  width: calc(100% + 4em);
  height: 0;
  padding-bottom: 56%;
  overflow: hidden;
  margin-bottom: 30px;
  margin-left: -2em;

  ${respondFrom(
    breakpoints.lg,
    css`
      width: 100%;
      padding-bottom: 40%;
      margin-left: 0;
      margin-bottom: 40px;
    `
  )}
`;

const ArticleImageStyled = styled(Image)`
  width: 100% !important;
  height: 670px !important;
  position: absolute !important;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
`;

interface ArticleImageProps {
  article: Blog | null;
}

export const ArticleImage = (props: ArticleImageProps) => (
  <>
    {props.article && props.article.image && (
      <ArticleImageWrapper>
        <ArticleImageStyled
          {...props.article.image.childImageSharp}
          imgStyle={{ objectFit: 'contain' }}
        />
      </ArticleImageWrapper>
    )}
  </>
);
