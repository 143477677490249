import React from 'react';
import styled from '@emotion/styled';
import { colors, mixins, dimensions, respondFrom, breakpoints, css } from '@styles';
import { Blog, NewsSettings } from '@content/types/blog';

import IconFb from '@assets/svg-inline/logo-fb.svg';
import IconTw from '@assets/svg-inline/logo-tw.svg';
import IconMail from '@assets/svg-inline/logo-mail.svg';
import IconPinterest from '@assets/svg-inline/logo-pinterest.svg';
import IconT from '@assets/svg-inline/logo-t.svg';

const ArticleShareWrapper = styled.div`
  margin: 25px 0;
  display: flex;
  flex-flow: column;
  align-items: center;

  ${respondFrom(
    breakpoints.md,
    css`
      margin: 35px 0;
      flex-flow: row;
    `
  )}

  a {
    display: inline-block;
    position: relative;
    padding: 0 6px;

    &:before {
      /* hover effect - circle enlarge */
      content: '';
      display: inline-block;
      ${mixins.absoluteCenter}
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: ${colors.text.default} solid 4px;

      ${mixins.transitionDefault}
      transform: translate(-50%, -50%) scale(0.9);
      transform-origin: center center;
    }

    svg {
      position: relative;
      top: 3px;
      width: 26px;
      height: 26px;
    }

    &:hover {
      &:before {
        transform: translate(-50%, -50%);
        opacity: 1;
      }
    }

    &:after {
      display: none;
    }
  }
`;

const ArticleShareText = styled.div`
  margin-bottom: 10px;
  font-size: ${dimensions.fontSize.small}px;
  color: ${colors.text.article};

  ${respondFrom(
    breakpoints.md,
    css`
      margin-right: 10px;
      margin-bottom: 0;
    `
  )}
`;

interface ArticleShareProps {
  href: string;
  article: Blog | null;
  settings: NewsSettings;
}

export const ArticleShare = (props: ArticleShareProps) => (
  <ArticleShareWrapper>
    <ArticleShareText>{props.settings.text6}</ArticleShareText>
    {props.article && (
      <div>
        <a
          href={`https://twitter.com/intent/tweet/?text=${props.article.title}&amp;url=${props.href}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Share on Twitter"
        >
          <IconTw />
        </a>
        <a
          href={`https://facebook.com/sharer/sharer.php?u=${props.href}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Share on Facebook"
        >
          <IconFb />
        </a>
        <a
          href={`mailto:?subject=${props.article.title}&amp;body=${props.href}`}
          target="self"
          rel="noopener"
          aria-label="Share by E-Mail"
        >
          <IconMail />
        </a>
        <a
          href={`https://pinterest.com/pin/create/button/?url=${props.href}&amp;media=${props.href}&amp;description=${props.article.title}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Share on Pinterest"
        >
          <IconPinterest />
        </a>
        <a
          href={`https://www.tumblr.com/widgets/share/tool?posttype=link&amp;title=${props.article.title}&amp;caption=${props.article.title}&amp;content=${props.href}&amp;canonicalUrl=${props.href}&amp;shareSource=${props.href}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Share on Tumblr"
        >
          <IconT />
        </a>
      </div>
    )}
  </ArticleShareWrapper>
);
