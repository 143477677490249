import React from 'react';
import styled from '@emotion/styled';
import { widths, respondFrom, respondTo, breakpoints, css } from '@styles';
import Image from 'gatsby-image';
import * as utils from '@utils';

const ArticleImageTextWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: ${widths.articleMax}px;
  margin: 20px auto;

  ${respondFrom(
    breakpoints.md,
    css`
      flex-flow: row;
      margin: 40px auto;
    `
  )}

  h3 {
    font-weight: 100;
  }

  p {
    line-height: 1.5;
  }


  .gatsby-image-wrapper {
    ${respondTo(
      breakpoints.md,
      css`
        width: 100% !important;
        height: 225px !important;
        margin-bottom: 20px;
      `
    )}

    ${respondFrom(
      breakpoints.md,
      css`
        min-width: 400px;
        transform: translateX(-30px);
      `
    )}

    ${respondFrom(
      breakpoints.lg,
      css`
        transform: translateX(-50px);
      `
    )}
  }
`;

export const ArticleSectionImageText = ({ data }) => (
  <ArticleImageTextWrapper>
    {data && data.image !== null && (
      <Image {...data.image.childImageSharp} imgStyle={{ objectFit: 'contain' }} />
    )}
    <div>
      {data.title && data.title !== '' && <h3>{data.title}</h3>}
      {utils.SafeHtml(data.content)}
    </div>
  </ArticleImageTextWrapper>
);
