import React from 'react';
import { Blog, NewsSettings } from '@content/types/blog';
import { ArticleHeader } from '@components/article/ArticleHeader';
import { ArticleImage } from '@components/article/ArticleImage';
import { ArticleContent } from '@components/article/ArticleContent';
import { ArticleShare } from '@components/article/ArticleShare';
import { ArticleRelatedArticles } from '@components/article/ArticleRelatedArticles';

interface ArticleGridProps {
  allArticles: Blog[];
  article: Blog | null;
  href: string;
  settings: NewsSettings;
  langcode: string;
  urls: Array<string>;
}

export const ArticleGrid = (props: ArticleGridProps) => {
  return (
    <>
      <ArticleHeader {...props} />
      <ArticleImage {...props} />
      <ArticleContent {...props} />

      <ArticleShare {...props} />
      <ArticleRelatedArticles
        allArticles={
          props && props.article && props.article.relatedArticles
            ? props.article.relatedArticles
            : []
        }
        settings={props.settings}
        langcode={props.langcode}
        urls={props.urls}
      />
    </>
  );
};
