/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import NewsContainer from '@containers/article';
import useNewsQuery from '@graphql/queries/NewsQuery';
import { PageContext } from '@content/types/pageContext';
import { LocationDependent } from '@content/types/general';
import DataLayer from '@components/layout/DataLayer';

interface NewsPageProps extends LocationDependent {
  pageContext: PageContext;
}

export default (props: NewsPageProps) => {
  const newsQuery = useNewsQuery();

  return (
    <>
      <DataLayer location={props.location} />
      <NewsContainer
        {...newsQuery}
        pageContext={props.pageContext}
        location={props.location}
        urls={props.pageContext.allUrls}
      />
    </>
  );
};
